// Custom.scss
// Option B: Include parts of Bootstrap

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "./../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here

// 3. Include remainder of required Bootstrap stylesheets
@import "./../node_modules/bootstrap/scss/variables";
@import "./../node_modules/bootstrap/scss/mixins";

// 5. Add additional custom code here

body {
    background: #000;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: #FFF;
}
.brand {
    font-family: 'Libre Bodoni', serif;
    text-transform: uppercase;
    color: #FFF;
    text-decoration: none;
}
.clickable {
    cursor: pointer;
}

.hero {
    &__image {
        width: 100%;
        object-fit: cover;
    }
    &__blurb {
        width: 75%;
    }
    @include media-breakpoint-up(lg) {
        &--half {
            width: 50%;
        }
        height: calc(100vh - 96px);
        &__image {
            height: 100%;
        }
        &__blurb {
            margin-bottom: 5vh;
        }
    }
}

.btn {
    border-radius: 0;
    font-weight: 600;
    text-transform: uppercase;
    padding: .75rem 1.25rem;
    &-transparent {
        background-color: rgba(255, 255, 255, 0.85);
        border: 1px solid #000;
        color: #000;
        transition: background-color .5s ease, color .5s ease;

        &:hover {
            background-color: rgba(0,0,0,0.75);
            color: #FFF;
        }
    }
    .bi {
        font-size: 2rem;
    }
}

.youtube {
    &__responsive-wrapper {
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
          }
    }
}